import React, { useContext, useEffect, useState } from "react"

import { Accordion, AccordionItem } from "@nextui-org/accordion"
import {
  Button,
  Container,
  Dialog,
  DialogContent,
  IconButton,
  Menu,
  MenuItem,
  Theme,
  Typography,
  useMediaQuery,
} from "@suraasa/placebo-ui"
import clsx from "clsx"
import Image from "next/image"
import Link from "next/link"
import SuraasaLogoNew from "public/assets/logos/suraasa-logo-new.svg"
import Certificate from "public/assets/navbar/Certificate"
import InstagramLogo from "public/assets/navbar/instagram-logo.svg"
import WebinarLogo from "public/assets/navbar/webinar-logo.svg"
import WebinarLogoBlack from "public/assets/navbar/webinar-logo-black.svg"
import { createUseStyles, useTheme } from "react-jss"

import {
  BookmarkBook,
  BookStack,
  Calendar,
  Flare,
  GoogleDocs,
  GraduationCap,
  GraphUp,
  JournalPage,
  Language,
  LightBulbOn,
  Mail,
  Menu as MenuIcon,
  MicrophoneSpeaking,
  ModernTv,
  MoneySquare,
  NavArrowDown,
  Play,
  Translate,
} from "iconoir-react"

import { USER_TYPE } from "utils/constants"
import { getPlatformURL, getSSOUrl } from "utils/helpers"

import CTAButton from "./landingPage/shared/CTAButton"
import NoSSR from "./shared/NoSSR"
import { GlobalContext } from "./GlobalContext"
import ResourceCard from "./ResourceCard"

const useStyles = createUseStyles(theme => ({
  navbarContainer: {
    position: "sticky",
    top: 0,
    zIndex: theme.zIndex.dialog - 1,
  },
  fixedNav: {
    width: "100%",
    position: "fixed",
  },

  zIndexAdjust: {
    zIndex: theme.zIndex.dialog + 1,
  },

  accordionRoot: {
    "& svg": {
      color: "black",
    },
  },
  accordionHeader: {
    paddingLeft: 0,
  },
  accordionDetails: {
    paddingLeft: 0,
  },

  root: {
    backgroundColor: "white",
    borderBottom: `1px solid ${theme.colors.onSurface[200]}`,
    position: "relative",
  },
  gutterBottom: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up("md")]: {
      marginBottom: theme.spacing(8),
    },
  },

  navbarButton: {
    "&:hover": {
      background: theme.colors.onSurface[50],
    },
  },

  containerRoot: {
    width: "100%",
  },
  container: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
      justifyContent: "space-between",
    },

    "& .szh-menu__item--active": {
      background: theme.colors.primary[50],
      color: theme.colors.primary[600],
    },
  },
  link: {
    color: "inherit",
    textDecoration: "none",
  },
  mobileMenuContainer: {
    // position: "absolute",
    background: "white",
    top: "5%",
    zIndex: theme.zIndex.dialog + 1,

    width: "100%",
    height: "100%",
    left: 0,

    "& span": { textAlign: "left" },
  },

  fullWidthButtons: {
    "&:hover": {
      background: theme.colors.primary[50],
    },
    "& span": {
      justifyContent: "start",
      cursor: "pointer",
    },
    "& a": {
      paddingLeft: 0,
    },
  },

  mobileMenuItemContainer: {
    "& > span , & > a > span": {
      justifyContent: "start",
      padding: theme.spacing(1, 1.25),
      cursor: "pointer",

      "&:hover": {
        background: theme.colors.primary[50],

        "& span": {
          color: `${theme.colors.primary[600]} !important`,
        },
      },

      "&:active": {
        background: theme.colors.primary[100],
        outline: "none",
      },
    },

    "& > a ": {
      paddingLeft: 0,
    },
  },

  loginButton: {
    padding: theme.spacing(1.5, 0),
  },

  menuItem: {
    borderRadius: "4px !important",
    textDecoration: "none",
    fontSize: 14,
    fontWeight: 500,
    marginLeft: "0px !important",
    marginRight: "0px !important",
  },

  containerOnMenuOpen: {
    width: "100%",
    position: "fixed",
    top: 0,
  },
  dialogContainer: {
    paddingLeft: 38,
    paddingRight: 38,
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
  menuListContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
  },
}))

export type NavbarProps = {
  className?: string
  gutterBottom?: boolean
  slotEnd?: React.ReactNode
  isAdvertisementPage?: boolean
}

const menuData = {
  resources: {
    objectives: [
      {
        text: "Start Teaching",
        link: getPlatformURL("suraasa", "/resources/start-teaching"),
        isExternal: false,
        icon: Flare,
      },
      {
        text: "Teach Abroad",
        link: getPlatformURL("suraasa", "/resources/teach-abroad"),
        isExternal: false,
        icon: Language,
      },
      {
        text: "Get Promotions",
        link: getPlatformURL("suraasa", "/resources/get-promotions"),
        isExternal: true,
        icon: GraphUp,
      },
      {
        text: "Professional Development",
        link: getPlatformURL("suraasa", "/resources/professional-development"),
        isExternal: false,
        icon: LightBulbOn,
      },
      {
        text: "Find High-Paying Jobs",
        link: getPlatformURL("suraasa", "/resources/find-high-paying-jobs"),
        isExternal: false,
        icon: MoneySquare,
      },
    ],
    types: [
      {
        text: "Blog",
        link: getPlatformURL("suraasa", "/blog"),
        isExternal: false,
        icon: GoogleDocs,
      },
      {
        text: "Masterclasses",
        link: getPlatformURL("suraasa", "/masterclasses"),
        isExternal: false,
        icon: ModernTv,
      },
      {
        text: "Admissions Webinars",
        link: getPlatformURL(
          "suraasa",
          "/admissions-webinars?utm_source=Nav Bar&utm_medium=Website"
        ),
        isExternal: false,
        startAdornment: (
          <div className="flex items-center h-full">
            <img alt="Webinar" src={WebinarLogoBlack.src} />
          </div>
        ),
      },
      {
        text: "Newsletter",
        link: getPlatformURL("suraasa", "/newsletter"),
        isExternal: false,
        icon: Mail,
      },
      {
        text: "Podcast",
        link: "https://anchor.fm/suraasa",
        isExternal: true,
        icon: MicrophoneSpeaking,
      },
      {
        text: "Videos",
        link: "https://suraasa.co/youtube",
        isExternal: true,
        icon: Play,
      },
      {
        text: "In the news",
        link: getPlatformURL("suraasa", "/in-the-news"),
        isExternal: false,
        icon: JournalPage,
      },
    ],
  },
  featured: [
    {
      text: "PgCTL (Diploma in Teaching)",
      link: getPlatformURL(
        "suraasa",
        "/store/qualifications/professional-graduate-certificate-in-teaching-learning"
      ),
      isExternal: false,
      icon: GraduationCap,
    },
    {
      text: "MPD Courses",
      link: getPlatformURL(
        "suraasa",
        "/store/certifications/mandatory-professional-development-courses"
      ),
      isExternal: false,
      icon: BookmarkBook,
    },
    {
      text: "Speak English like an International Teacher",
      link: getPlatformURL(
        "suraasa",
        "/store/courses/speak-english-like-an-international-teacher"
      ),
      isExternal: false,
      icon: Translate,
    },
  ],

  categories: [
    {
      text: "Qualifications",
      link: getPlatformURL("suraasa", "/store/qualifications"),
      isExternal: false,
      icon: GraduationCap,
    },
    {
      text: "Courses",
      link: getPlatformURL("suraasa", "/store/courses"),
      isExternal: false,
      icon: Certificate,
      isCustomIcon: true,
    },
    {
      text: "Packages",
      link: getPlatformURL("suraasa", "/store/packages"),
      isExternal: false,
      icon: BookStack,
    },
  ],
}
const Navbar = ({
  gutterBottom = true,
  className,
  slotEnd,
  isAdvertisementPage = false,
}: NavbarProps) => {
  const { isLoggedIn } = useContext(GlobalContext)
  const classes = useStyles()
  const theme = useTheme<Theme>()

  const isSm = useMediaQuery(theme.breakpoints.down("sm"))
  const isXs = useMediaQuery(theme.breakpoints.down("xs"))
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)

  useEffect(() => {
    if (!isSm) {
      setIsMobileMenuOpen(false)
    }
  }, [isSm])

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleSignin = () => {
    // This is to open popup in the center of the screen
    const width = 500
    const height = 600
    const x = window.top
      ? window.top.outerWidth / 2 + window.top.screenX - height / 2
      : 0
    const y = window.top
      ? window.top.outerHeight / 2 + window.top.screenY - width / 2
      : 0

    const windowFeatures = `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${width}, height=${height}, top=${y}, left=${x}`

    const popup = window.open(
      `${
        process.env.NEXT_PUBLIC_SSO_URL
      }/popup-sso/?platform=${USER_TYPE}&origin=${encodeURI(
        `${window.location.origin}`
      )}`,
      "SSO",
      windowFeatures
    )

    try {
      if (
        !popup ||
        popup.closed ||
        typeof popup.closed === "undefined" ||
        popup.outerHeight === 0 ||
        popup.outerWidth === 0
      ) {
        window.location.href = getSSOUrl()
      }
    } catch (e) {
      console.warn("> multiple sso windows opened")
    }
    // Fallback code if popup was blocked
  }

  if (isAdvertisementPage)
    return (
      <>
        <div className="h-7 bg-primary-50" />
        <div
          className={clsx(
            { [classes.gutterBottom]: gutterBottom },
            { [classes.zIndexAdjust]: isMobileMenuOpen },
            classes.navbarContainer,
            classes.fixedNav
          )}
        >
          <nav
            className={clsx(
              classes.root,
              className,
              {
                [classes.containerOnMenuOpen]: isMobileMenuOpen,
              },
              "py-1 flex items-center h-[54px]"
            )}
          >
            <Container className={classes.containerRoot}>
              <div className={clsx("justify-between", classes.container)}>
                <div
                  className={clsx(
                    "flex items-center justify-between flex-grow md:flex-grow-0 md:block",
                    {
                      "!justify-center": isXs,
                    }
                  )}
                >
                  <span>
                    <a
                      href={
                        isAdvertisementPage
                          ? undefined
                          : process.env.NEXT_PUBLIC_LEARN_PLATFORM_URL
                      }
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      <Image
                        alt="suraasa-logo"
                        aria-label="suraasa-logo"
                        className="h-[25px]"
                        decoding="sync"
                        height="25"
                        src={SuraasaLogoNew}
                        tabIndex={0}
                        width="150"
                        priority
                      />
                    </a>
                  </span>
                </div>
                {!isXs && (
                  <CTAButton
                    size="sm"
                    tagClassName="text-secondary-600"
                    hideTag
                  />
                )}
              </div>
            </Container>
          </nav>
        </div>
      </>
    )

  return (
    <div
      className={clsx(
        { [classes.gutterBottom]: gutterBottom },
        { [classes.zIndexAdjust]: isMobileMenuOpen },
        classes.navbarContainer
      )}
    >
      <nav
        className={clsx(
          classes.root,
          className,
          {
            [classes.containerOnMenuOpen]: isMobileMenuOpen,
          },
          "py-1 flex items-center",
          {
            "h-[62px] md:h-8": !slotEnd && !isLoggedIn,
            "h-[62px]": slotEnd || isLoggedIn,
          }
        )}
      >
        <Container className={classes.containerRoot}>
          <div className={clsx(classes.container)}>
            {/* Start */}

            <div className="md:hidden">
              <IconButton
                className="md:hidden"
                color="black"
                onClick={() => setIsMobileMenuOpen(prevState => !prevState)}
              >
                <MenuIcon />
              </IconButton>
            </div>

            <Dialog open={isMobileMenuOpen} fullScreen>
              <DialogContent
                className={clsx("my-4.5", classes.dialogContainer)}
              >
                <div
                  className={clsx("py-4.5 md:p-5", classes.mobileMenuContainer)}
                >
                  <div className="flex flex-col gap-1">
                    <Accordion
                      className="!px-0"
                      itemClasses={{
                        trigger: "py-2",
                        heading: "p-0",
                        content: "p-0",
                      }}
                    >
                      <AccordionItem
                        indicator={<NavArrowDown />}
                        title="Programs"
                      >
                        <div className="mt-1 sm:px-2 px-1.5 pb-1 pt-0 flex flex-col justify-start">
                          <Typography
                            className="mb-1.5"
                            color="onSurface.500"
                            variant="preTitle"
                          >
                            Featured
                          </Typography>
                          <div className={classes.mobileMenuItemContainer}>
                            {menuData.featured.map((item, index) => (
                              <Button
                                color="black"
                                component={item.isExternal ? "a" : Link}
                                href={item.link}
                                key={index}
                                startAdornment={<item.icon />}
                                // @ts-ignore placebo-issue
                                target={item.isExternal ? "_blank" : "_self"}
                                variant="text"
                                fullWidth
                              >
                                <Typography
                                  className="max-w-[300px]"
                                  variant="button"
                                >
                                  {item.text}
                                </Typography>
                              </Button>
                            ))}
                          </div>
                          <Typography
                            className="my-1.5"
                            color="onSurface.500"
                            variant="preTitle"
                          >
                            Categories
                          </Typography>
                          <div className={classes.mobileMenuItemContainer}>
                            {menuData.categories.map((item, index) => (
                              <Button
                                color="black"
                                component={item.isExternal ? "a" : Link}
                                href={item.link}
                                key={index}
                                startAdornment={<item.icon />}
                                // @ts-ignore placebo-issue
                                target={item.isExternal ? "_blank" : "_self"}
                                variant="text"
                                fullWidth
                              >
                                <Typography variant="button">
                                  {item.text}
                                </Typography>
                              </Button>
                            ))}
                            <Button
                              color="primary"
                              component={Link}
                              href={getPlatformURL("suraasa", "/store")}
                              variant="text"
                            >
                              <Typography
                                className="px-1 -ml-1"
                                color="interactive.500"
                                variant="button"
                              >
                                See All Categories
                              </Typography>
                            </Button>
                          </div>
                        </div>
                        <ResourceCard
                          startSlot={
                            <Calendar className="mr-1.5 w-2.5 h-2.5 !text-primary-500" />
                          }
                          theme={{
                            backgroundColor: "#EDF0FE",
                            text: "#4666F6",
                          }}
                          title="Talk to a Mentor"
                          url={getPlatformURL(
                            "suraasa",
                            "/talk-to-a-mentor?utm_source=Nav Bar&utm_medium=Website&utm_campaign=Growth Experiment&utm_content=&utm_term=&utm_audience="
                          )}
                        />
                        <ResourceCard
                          className="hover:!bg-[#FFF2E1]"
                          startSlot={
                            // eslint-disable-next-line @next/next/no-img-element
                            <img
                              alt="webinar-logo"
                              className={clsx("mr-1.5 w-2.5 h-2.5")}
                              src={WebinarLogo.src}
                            />
                          }
                          theme={{
                            backgroundColor: "#FCF6EE",
                            text: "#E5A256",
                          }}
                          title="Admissions Webinars"
                          url={getPlatformURL(
                            "suraasa",
                            "/admissions-webinars?utm_source=Nav Bar&utm_medium=Website"
                          )}
                        />
                      </AccordionItem>
                    </Accordion>
                    <div className={clsx(classes.fullWidthButtons, "py-1")}>
                      <Button
                        color="black"
                        component="a"
                        href={getPlatformURL("suraasa", "/success-stories")}
                        variant="text"
                        fullWidth
                      >
                        <Typography variant="button">
                          Success Stories
                        </Typography>
                      </Button>
                    </div>
                    <Accordion
                      className="!px-0"
                      itemClasses={{
                        trigger: "py-2",
                        heading: "p-0",
                        content: "p-0",
                      }}
                    >
                      <AccordionItem
                        indicator={<NavArrowDown />}
                        title="Resources"
                      >
                        <div className="mt-1 sm:px-2 px-1.5 pb-1 pt-0 flex flex-col justify-start">
                          <Typography
                            className="mb-1"
                            color="onSurface.500"
                            variant="preTitle"
                          >
                            Career Objective
                          </Typography>
                          <div className={classes.mobileMenuItemContainer}>
                            {menuData.resources.objectives.map(
                              (item, index) => (
                                <Button
                                  color="black"
                                  component={item.isExternal ? "a" : Link}
                                  href={item.link}
                                  key={index}
                                  startAdornment={<item.icon />}
                                  // @ts-ignore placebo-issue
                                  target={item.isExternal ? "_blank" : "_self"}
                                  variant="text"
                                  fullWidth
                                >
                                  <Typography variant="button">
                                    {item.text}
                                  </Typography>
                                </Button>
                              )
                            )}
                          </div>
                          <Typography
                            className="mt-2.75 mb-1"
                            color="onSurface.500"
                            variant="preTitle"
                          >
                            Resource Type
                          </Typography>
                          <div className={classes.mobileMenuItemContainer}>
                            {menuData.resources.types.map((item, index) => (
                              <Button
                                color="black"
                                component={item.isExternal ? "a" : Link}
                                href={item.link}
                                key={index}
                                startAdornment={
                                  item.startAdornment || <item.icon />
                                }
                                // @ts-ignore placebo-issue
                                target={item.isExternal ? "_blank" : "_self"}
                                variant="text"
                                fullWidth
                              >
                                <Typography variant="button">
                                  {item.text}
                                </Typography>
                              </Button>
                            ))}
                            <Button
                              color="primary"
                              component={Link}
                              href={getPlatformURL("suraasa", "/resources")}
                              variant="text"
                            >
                              <Typography
                                className="px-1 -ml-1"
                                color="interactive.500"
                                variant="button"
                              >
                                See All Resources
                              </Typography>
                            </Button>
                          </div>
                        </div>

                        <ResourceCard
                          startSlot={
                            // eslint-disable-next-line @next/next/no-img-element
                            <img
                              alt="instagram-logo"
                              className={clsx("mr-1.5 w-2 h-2")}
                              src={InstagramLogo.src}
                            />
                          }
                          theme={{
                            backgroundColor: "#FFEAF1",
                            text: "#E1306C",
                          }}
                          title="Instagram"
                          url="https://suraasa.co/instagram"
                        />
                      </AccordionItem>
                    </Accordion>
                    {/* <div
                      className={clsx(
                        classes.fullWidthButtons,
                        "px-2 py-1 mb-3"
                      )}
                    >
                      <Button
                        color="black"
                        component="a"
                        href={getPlatformURL("suraasa", "/jobs/school")}
                        variant="text"
                        fullWidth
                      >
                        <Typography variant="button">Hire Teachers</Typography>
                      </Button>
                    </div> */}
                    <div
                      className={clsx(classes.fullWidthButtons, "py-1 mb-3")}
                    >
                      <Button
                        color="black"
                        component="a"
                        href={getPlatformURL("suraasa", "/about-us")}
                        variant="text"
                        fullWidth
                      >
                        <Typography variant="button">About Us</Typography>
                      </Button>
                    </div>
                  </div>
                  {slotEnd || (
                    <NoSSR>
                      {isLoggedIn ? (
                        <Button
                          className={classes.loginButton}
                          component="a"
                          href={getPlatformURL("learn", "/home")}
                          fullWidth
                        >
                          Go to dashboard
                        </Button>
                      ) : (
                        <div className="flex flex-col gap-3 mt-4">
                          <Button
                            className={classes.loginButton}
                            component="a"
                            href={getSSOUrl()}
                            // onClick={handleSignin}
                            variant="text"
                            fullWidth
                          >
                            Sign In
                          </Button>
                          {/* <Button
                            component="a"
                            href={getSSOUrl({ signup: true })}
                            fullWidth
                          >
                            Sign Up
                          </Button> */}
                          <CTAButton
                            tagClassName="text-secondary-600"
                            fullWidth
                            hideTag
                          />
                        </div>
                      )}
                    </NoSSR>
                  )}
                </div>
              </DialogContent>
            </Dialog>

            <div className="flex items-center justify-center flex-grow md:flex-grow-0 md:block">
              <span>
                <a href={process.env.NEXT_PUBLIC_LEARN_PLATFORM_URL}>
                  <Image
                    alt="suraasa-logo"
                    aria-label="suraasa-logo"
                    className="h-[25px]"
                    decoding="sync"
                    height="25"
                    src={SuraasaLogoNew}
                    tabIndex={0}
                    width="150"
                    priority
                  />
                </a>
              </span>
            </div>

            <div className="items-center justify-between flex-grow hidden md:flex">
              <div className="flex ml-2.5">
                <Menu
                  menuButton={
                    <Button
                      className={classes.navbarButton}
                      color="black"
                      endAdornment={<NavArrowDown />}
                      variant="text"
                    >
                      Programs
                    </Button>
                  }
                >
                  <div
                    className={clsx(
                      "gap-2 px-2 py-1",
                      classes.menuListContainer
                    )}
                  >
                    <div>
                      <Typography
                        className="mb-1"
                        color="onSurface.500"
                        variant="preTitle"
                      >
                        Featured
                      </Typography>
                      {menuData.featured.map((item, index) => (
                        <MenuItem
                          className={clsx(
                            classes.menuItem,
                            "max-w-[240px] !items-start"
                          )}
                          href={item.link}
                          key={index}
                          startAdornment={<item.icon />}
                          target={item.isExternal ? "_blank" : "_self"}
                        >
                          {item.text}
                        </MenuItem>
                      ))}
                    </div>
                    <div>
                      <Typography
                        className="mb-1"
                        color="onSurface.500"
                        variant="preTitle"
                      >
                        Categories
                      </Typography>
                      {menuData.categories.map((item, index) => (
                        <MenuItem
                          className={classes.menuItem}
                          href={item.link}
                          key={index}
                          startAdornment={<item.icon />}
                          target={item.isExternal ? "_blank" : "_self"}
                        >
                          {item.text}
                        </MenuItem>
                      ))}

                      <MenuItem
                        className={classes.menuItem}
                        href={getPlatformURL("suraasa", "/store")}
                        target="_blank"
                      >
                        <Typography color="interactive.500" variant="smallBody">
                          See All Categories
                        </Typography>
                      </MenuItem>
                    </div>
                  </div>
                  <ResourceCard
                    description="Expert Guidance & Support for Your Teaching Career"
                    startSlot={<Calendar className="mr-1.5 w-5 h-5" />}
                    theme={{
                      backgroundColor: "#EDF0FE",
                      text: "#4666F6",
                    }}
                    title="Talk to a Mentor"
                    url="https://suraasa.co/MHH9"
                  />
                  <ResourceCard
                    className="hover:!bg-[#FFF2E1]"
                    description="Interact with mentors & alumni to get program insights!"
                    startSlot={
                      // eslint-disable-next-line @next/next/no-img-element
                      <img
                        alt="webinar-logo"
                        className={clsx("mr-1.5 w-5 h-5 !text-[#E5A256]")}
                        src={WebinarLogo.src}
                      />
                    }
                    theme={{
                      backgroundColor: "#FCF6EE",
                      text: "#E5A256",
                    }}
                    title="Admissions Webinars"
                    url={getPlatformURL(
                      "suraasa",
                      "/admissions-webinars?utm_source=Nav Bar&utm_medium=Website"
                    )}
                  />
                </Menu>

                <Button
                  className={clsx("mx-1", classes.navbarButton)}
                  color="black"
                  component="a"
                  href={getPlatformURL("suraasa", "/success-stories")}
                  variant="text"
                >
                  Success Stories
                </Button>
                <Menu
                  menuButton={
                    <Button
                      className={classes.navbarButton}
                      color="black"
                      endAdornment={<NavArrowDown />}
                      variant="text"
                    >
                      Resources
                    </Button>
                  }
                >
                  <div
                    className={clsx(
                      "gap-2 px-2 pb-2 pt-1",
                      classes.menuListContainer
                    )}
                  >
                    <div>
                      <Typography
                        className="mb-1"
                        color="onSurface.500"
                        variant="preTitle"
                      >
                        Career Objective
                      </Typography>
                      {menuData.resources.objectives.map((item, index) => (
                        <MenuItem
                          className={classes.menuItem}
                          href={item.link}
                          key={index}
                          startAdornment={<item.icon />}
                          target={item.isExternal ? "_blank" : "_self"}
                        >
                          {item.text}
                        </MenuItem>
                      ))}
                    </div>
                    <div>
                      <Typography
                        className="mb-1"
                        color="onSurface.500"
                        variant="preTitle"
                      >
                        Resource Type
                      </Typography>
                      {menuData.resources.types.map((item, index) => (
                        <MenuItem
                          className={classes.menuItem}
                          href={item.link}
                          key={index}
                          startAdornment={item.startAdornment || <item.icon />}
                          target={item.isExternal ? "_blank" : "_self"}
                        >
                          {item.text}
                        </MenuItem>
                      ))}

                      <MenuItem
                        className={classes.menuItem}
                        href={getPlatformURL("suraasa", "/resources")}
                        target="_blank"
                      >
                        <Typography color="interactive.500" variant="smallBody">
                          See All Resources
                        </Typography>
                      </MenuItem>
                    </div>
                  </div>

                  <ResourceCard
                    description="Daily dose of motivation for your teaching journey"
                    startSlot={
                      // eslint-disable-next-line @next/next/no-img-element
                      <img
                        alt="instagram-logo"
                        className={clsx("mr-1.5 w-2 h-2 sm:w-auto sm:h-auto")}
                        src={InstagramLogo.src}
                      />
                    }
                    theme={{ backgroundColor: "#FFEAF1", text: "#E1306C" }}
                    title="Instagram"
                    url={getPlatformURL("suraasa", "/instagram")}
                  />
                </Menu>
                <Button
                  className={clsx("mx-1", classes.navbarButton)}
                  color="black"
                  component="a"
                  href={getPlatformURL("suraasa", "/about-us")}
                  variant="text"
                >
                  <Typography variant="button">About Us</Typography>
                </Button>
              </div>

              <div className="flex items-center gap-2.75">
                {slotEnd || (
                  <NoSSR>
                    {/* <Button
                      className={classes.navbarButton}
                      color="black"
                      component="a"
                      href={getPlatformURL("suraasa", "/jobs/school")}
                      variant="text"
                    >
                      Hire Teachers
                    </Button> */}
                    {isLoggedIn ? (
                      <Button
                        component="a"
                        href={getPlatformURL("learn", "/home")}
                        size="sm"
                        fullWidth
                      >
                        Go to dashboard
                      </Button>
                    ) : (
                      <>
                        <Button
                          color="primary"
                          component="a"
                          // onClick={handleSignin}
                          href={getSSOUrl()}
                          variant="text"
                        >
                          Sign In
                        </Button>
                        {/* <Button
                          component="a"
                          href={getSSOUrl({ signup: true })}
                          size="sm"
                        >
                          Sign Up
                        </Button> */}
                        <CTAButton
                          size="sm"
                          tagClassName="text-secondary-600"
                          hideTag
                        />
                      </>
                    )}
                  </NoSSR>
                )}
              </div>
            </div>
          </div>
        </Container>
      </nav>
    </div>
  )
}

export default Navbar
