import React from "react"

import { Typography } from "@suraasa/placebo-ui"
import clsx from "clsx"
import { createUseStyles } from "react-jss"

type Props = {
  theme?: {
    backgroundColor?: string
    text?: string
  }
  className?: string
  startSlot?: React.ReactNode
  description?: string
  title: string
  url: string
}

const useStyles = createUseStyles(theme => ({
  "@keyframes diagonal-slide": {
    "0%": { opacity: 1, transform: "translate(0px,0px) scale(1)" },
    "25%": { opacity: 0, transform: "translate(5px,-5px) scale(0.9)" },
    "26%": { opacity: 0, transform: "translate(-5px,5px) scale(0.9)" },
    "55%": { opacity: 1, transform: "translate(0px,0px) scale(1)" },
  },
  cardRoot: {
    // background: "#FFEAF1",
    borderRadius: 4,
    padding: "12px !important",
    "&:hover": {
      "& .arrow": {
        animationName: "$diagonal-slide",
        animationDuration: "1s",
        animationIterationCount: "1",
        animationTimingFunction: "linear",
      },
    },
  },
  cardTitle: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 16,
    lineHeight: "130%",
  },
  circleArrow: {
    border: "2px solid",
    borderRadius: "50%",
    width: 23.33,
    height: 23.33,
    bottom: 16,
    left: 16,
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      bottom: "auto",
      left: "auto",
      top: 16,
      right: 16,
      width: 18,
      height: 18,
    },
  },
}))

const ResourceCard = ({
  startSlot,
  theme,

  description,
  title,
  url,
  className,
}: Props) => {
  const classes = useStyles()

  return (
    <a
      className={clsx(
        classes.cardRoot,
        "md:mx-2 md:mb-1 mb-2 p-1.5 flex items-center justify-between",
        className
      )}
      href={url}
      rel="noreferrer"
      style={{
        backgroundColor: theme?.backgroundColor,
        color: theme?.text,
      }}
      target="_blank"
    >
      <div className="flex items-center">
        <div>{startSlot}</div>
        <div>
          <span className={classes.cardTitle}>{title}</span>
          {description && (
            <Typography color="onSurface.800" variant="smallBody">
              {description}
            </Typography>
          )}
        </div>
      </div>
      <div
        className={clsx(
          "grid items-center justify-center shrink-0",
          classes.circleArrow
        )}
      >
        {/* <Image
          alt="arrow"
          className="arrow"
          height={10}
          src="/assets/navbar/arrow.svg"
          width={9}
        /> */}
        <svg
          className="arrow"
          fill="none"
          height="9"
          viewBox="0 0 10 9"
          width="10"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.70048 7.8002L8.30015 1.20054M8.30015 1.20054L2.52544 1.20054M8.30015 1.20054L8.30015 6.97524"
            stroke={theme?.text}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.8"
          />
        </svg>
      </div>
    </a>
  )
}

export default ResourceCard
